import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import logo_stw3 from './logo_stw3.png';
import logo_devhub from './logo_devhub.png';
import logo_axregio from './logo_axregio.png';
import ThemeHandler from './ThemeHandler';

let {theme} = ThemeHandler();
let logo = logo_devhub;
switch (theme) {
  case 'stw3':
    logo = logo_stw3;
    break;
    case 'axregio':
      logo = logo_axregio;
      break;    
  default:
    break;
}

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
<header className="flex items-center justify-between bg-white p-10 pl-14 fixed h-32 z-50 border-b border-gray-200 w-full">
   <img style={{width:"auto", height:"40px" }} src={logo} />
   <div className="flex items-center">
      <span className="bg-blue-500 text-white text-xs font-bold uppercase px-2 py-1 rounded-full mr-3">BETA</span>
   </div>
</header>
  )
}
