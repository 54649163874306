
function ThemeHandler() {
    const domain = window.location.hostname;

    let theme;
    if (domain.includes('axregio.de')) {
      theme = 'axregio';
    } else if (domain.includes('stellwerk3.de')) {
      theme = 'stw3'
    } else {
      theme = 'devhub'
    }
    return {theme}
  }
  
  export default ThemeHandler ;