function formatText(text) {
    if(text){
        text = text.charAt(0).toUpperCase() + text.slice(1);
        text = text.replace(/[_>.]/g, " ");
        if(text == "-"){
            return "";
        }else{
            return text;
        }
    }else{
        return "";
    }
  }
  
  export { formatText };