import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Map from './components/Map';


function App() {
  return (
    <div className="w-full h-full app-container">
      <Header />
      <Map />    
    </div>
  );
}

export default App;
