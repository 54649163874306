import React, { useState, useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import axios from 'axios';
import markerIconRed from './doohicon.png';
import markerIconBlue from './doohicon_blue.png';
import markerIconBlack from './doohicon_black.png';
import markerIconColor from './doohicon_color.png';
import Sidebar from './Sidebar';
import mapstyle from './mapstyle';
import CryptoJS from "crypto-js";
import { formatText } from './FormatText';
import ThemeHandler from './ThemeHandler';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

const apiUrl = process.env.REACT_APP_API_URL;
const token = process.env.REACT_APP_API_TOKEN;
let { theme } = ThemeHandler();
let markerIcon = markerIconBlack;

switch (theme) {
  case 'stw3':
    markerIcon = markerIconRed;
    break;

  case 'axregio':
    markerIcon = markerIconBlue;
    break;    

  default:
    break;
}

const loadMarker = () => axios.get(apiUrl + "api/d/l", { headers: { Authorization: `Bearer ${token}` }})
  .then(response => response.data.data)
  .catch(error => {
    console.error(error);
    return {};
  });

const loadMarkerForPosition = (lat, lng) => axios.get(apiUrl + "api/d/p", { 
    headers: { Authorization: `Bearer ${token}` },
    params: { lat, lng } 
  })
  .then(response => response.data.data)
  .catch(error => {
    console.error(error);
    return {};
  });

let placedMarker = {};
let currentInfoWindow = null;

const placeMarkers = (map, markers) => {
  if (Object.keys(placedMarker).length > 2000) {
    for (const key in placedMarker) {
      if (placedMarker.hasOwnProperty(key)) {
        placedMarker[key].setMap(null);
      }
    }
    placedMarker = {};
  }

  if (markers.length > 0) {
    markers.forEach(markerData => {
      if (placedMarker[markerData.id]) {
        return;
      }

      placedMarker[markerData.id] = true;
      const marker = new window.google.maps.Marker({
        position: { lat: parseFloat(markerData.latitude), lng: parseFloat(markerData.longitude) },
        map: map,
        icon: {
          url: markerIcon,
          scaledSize: new window.google.maps.Size(59, 95),
        },
        title: markerData.name,
      });

      placedMarker[markerData.id] = marker;

      const infoWindow = new window.google.maps.InfoWindow({
        content: `<div style="display:grid; grid-template-columns: 1fr 1fr;">
                  <div style="border:20px solid; padding:1px; background:#2c2c2c; border-radius: 15px; margin:10px"><iframe width="250px" height="444px" src="preview.html?city=${markerData.city}"/></iframe></div>
                  <div class="p-5 pt-10">
                  <h3 class='text-lg pt-4 font-bold border-t border-gray-200'>${formatText(markerData.name)}</h3>
                  <p class="mt-2 text-sm text-gray-500">${formatText(markerData.venuetype)}</p>
                  <p class="mt-2 pb-4 text-lg border-b border-gray-200">${markerData.postcode} ${markerData.city}</p></div>
                  </div>`
      });

      marker.addListener('click', () => {
        // Close the current infoWindow if there is one
        if (currentInfoWindow) {
          currentInfoWindow.close();
        }

        // Open the new infoWindow
        infoWindow.open(map, marker);

        // Update the current infoWindow
        currentInfoWindow = infoWindow;
      });
    });
  }
};

const convertData = (height) => {
  const url = 'api/dooh/markers';
  const rawData = CryptoJS.enc.Base64.parse(height);
  const iv = rawData.clone();
  iv.sigBytes = 16;
  rawData.words.splice(0, 4);
  rawData.sigBytes -= 16;

  const scrollHeightData = CryptoJS.enc.Base64.stringify(rawData);
  const scrollHeightNew = CryptoJS.AES.decrypt(scrollHeightData, CryptoJS.enc.Utf8.parse(url), { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  const scrollHeightOld = CryptoJS.enc.Utf8.stringify(scrollHeightNew);
  const newHeight = JSON.parse(scrollHeightOld);

  return newHeight; 
};

const Map = () => {
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let timeoutId = null;

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
      libraries: ["places"],
      version: 'weekly',
    });

    loader.load().then(() => {
      const newMap = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: 48.143909348635184, lng: 11.566410462009285 },
        styles: mapstyle,
        zoom: 15,
      });

      setMap(newMap);

      const input = document.getElementById("search");
      const searchBox = new window.google.maps.places.SearchBox(input);
      newMap.addListener("bounds_changed", () => {
        searchBox.setBounds(newMap.getBounds());
      });

      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();
        newMap.fitBounds(places[0].geometry.viewport);
      });
    });
  }, []);

  useEffect(() => {
    if (map) {
      const listener = map.addListener('idle', () => {
        setIsLoading(true);

        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
          const center = map.getCenter();
          const zoom = map.getZoom();
          console.log(Object.keys(placedMarker).length);
          
          if (Object.keys(placedMarker).length > 2000) {
            setMarkers({});
          }

          setIsLoading(false);
          loadMarkerForPosition(center.lat(), center.lng()).then(marker => {
            const objMarker = convertData(marker);
            console.log(objMarker);
            setMarkers(objMarker);
          });
        }, 1000);
      });

      return () => {
        window.google.maps.event.removeListener(listener);
      };
    }
  }, [map]);

  useEffect(() => {
    if (map) {
     placeMarkers(map, markers);
    }
  }, [map, markers]);

  return (
    <div className="pt-32 flex flex-row h-full">
      <div className="basis-1/5">
        <Sidebar locations={markers} map={map} placedMarker={placedMarker} />
      </div>
      <div className="basis-4/5 relative">
        <div className="absolute top-0 left-0 right-0 h-1 bg-gray-200 z-50">
          <div className={`h-1 bg-gradient-to-r from-pink-500 via-white-500 to-yellow-500 background-animate  ${isLoading ? 'opacity-100' : 'opacity-0'}`} />
        </div>
        <div id="map" className='w-full h-full'></div>
      </div>
    </div>
  )
};

export default Map;