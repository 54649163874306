import { useState,useEffect } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { MapPinIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { formatText } from './FormatText';

export default function Sidebar(props) {

  const [locations, setLocations] = useState({...props.locations});
  const baseLocations= props.locations;

  useEffect(() => {
    setLocations(props.locations);
  }, [props.locations])

  const placedMarker = props.placedMarker;
  const map = props.map
  const [search, setSearch] = useState('');
  const handleClick = (location) => {
  
    window.google.maps.event.trigger(placedMarker[location.id], 'click');
    const clickedLatLng = {
      lat:parseFloat(location.latitude),
      lng: parseFloat(location.longitude),
    };
    map.panTo(clickedLatLng);
    map.setZoom(15);

  };

  

  const handleSearchLocations = (event) => {
    setSearch(event.target.value);
    const filterBy = event.target.value.toLowerCase();

    const filteredLocations = baseLocations.reduce((acc, location) => {
      const nameMatch = location.name.toLowerCase().includes(filterBy);
      const cityMatch = location.city.toLowerCase().includes(filterBy);
      if (!filterBy || nameMatch || cityMatch) {
        acc.push(location);
      }
      return acc;
    }, []);
  
    setLocations(filteredLocations);

  }


 
  return (
    <>
      <div className="pl-14 pr-14 border-b border-gray-200 h-[10%] flex items-center">
        <div className="relative w-4/5 mt-2 rounded-md shadow-sm w-full w-[360px]">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            name="kissffas"
            id="search"
            defaultValue={search}
            //onKeyDown={handleSearch}
            autoComplete="new-password"
            className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
            placeholder="Karte ausrichten"
          />
        </div>





      </div>

      <div className="h-[90%] overflow-y-auto">
        {locations.length > 0 ? (
          locations.map((location, index) => (
            <div
              key={index}
              onClick={() => handleClick(location)}
              className="p-7 pt-7 m-7 mt-0 mb-0 border-b border-gray-200 cursor-pointer hover:bg-gray-100"
            >
              <h3 className="text-base font-medium text-gray-900">
                {formatText(location.name)}
              </h3>
              {location.venuetype ? (
                <p className="mt-1  text-sm text-gray-500 truncate">
                  {formatText(location.venuetype)}
                </p>
              ) : (
                ""
              )}

              <p className="mt-1 flex items-center text-sm text-gray-500">
                <MapPinIcon className="h-4 w-4 text-gray-500" />
                <span className="max-w-xs ml-1 truncate">
                  {location.postcode} {location.city}
                </span>
              </p>
            </div>
          ))
        ) : (
          <div className="p-7 pt-0 m-7 border-b border-gray-200 cursor-pointer">
            <h3 className="text-base font-medium text-gray-900">
              Keine Ergebnisse
            </h3>
          </div>
        )}
      </div>
    </>
  );
}
